<template>
  <v-container class="mx-0 my-0 py-0" style="margin: auto !important;">
    <ScrollPagination
      v-if="$route.params.roomId"
      :requestData="{
        'url': `posts/${this.user.id}`,
        'params': {
          room: this.$route.params.roomId
        }
      }"
      :countData="{
        'url': `posts/${this.user.id}/count`,
      }"
      :key="renderLength"
      :rootHeight="`${scrollListHeight}px`"
    >
      <template
        v-if="isActive"
        v-slot:staticSlot
      >
      <v-container class="py-0 px-0">
        <v-row style="width: 100%; max-width: 100%;"class="px-0 mb-3">
          <v-col cols="8" class="py-0">
            <ActionsHeader
              v-if="room"
              :room="room"
              :rerenderPostList="rerenderPostList"
            />
          </v-col>
          <v-col 
            cols="4" 
            class="py-0" 
            style="position: relative;"
          >
            <SidebarRight />
          </v-col>
        </v-row>
      </v-container>
    </template>
      
      <template v-slot:child="props">
        <v-row style="width: 100%; max-width: 100%;">
          <v-col cols="8" class="py-0">
            <v-card 
              rounded="xl" 
              :elevation="1" 
              style="width: 100%;"
              class="mb-3"
              @click="() => $router.push(`/community/${community._id}/post/${props.item._id}`)"
            >
              <Post
                :post="props.item"
                :showComments="false"
              />
            </v-card>
          </v-col>
          <v-col cols="4" class="py-0">
          </v-col>
        </v-row>
      </template>
    </ScrollPagination>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import RoomService from '@api/room'
  import ActionsHeader from '@components/Community/ActionsHeader'
  import ScrollPagination from '@components/App/ScrollPagination'
  import Post from '@components/Community/Post/Post'
  import SidebarRight from '@components/Community/SidebarRight'

  export default {
    components: {
      ActionsHeader,
      ScrollPagination,
      Post,
      SidebarRight
    },

    data() {
      return {
        roomService: null,
        room: null,
        renderLength: 0
      }
    },

    watch: {
      $route: {
        async handler (value) {
          if (value.params.roomId) {
            this.room = await this.roomService.getById(this.$route.params.roomId)
          } else this.resetRoom()
        }
      }
    },

    async created() {
      this.roomService = RoomService(this.user.id)
      if (this.$route.params.roomId) {
        this.room = await this.roomService.getById(this.$route.params.roomId)
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        innerHeight: 'innerHeight',
        isActive: 'community/isActive',
        community: 'community/community'
      }),
      scrollListHeight() {
        return this.innerHeight - 90
      }
    },

    methods: {
      resetRoom() {
        this.room = null
      },
      rerenderPostList() {
        this.renderLength = this.renderLength + 1
      }
    }
  }
</script>
