var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mx-0 my-0 py-0",staticStyle:{"margin":"auto !important"}},[(_vm.$route.params.roomId)?_c('ScrollPagination',{key:_vm.renderLength,attrs:{"requestData":{
      'url': ("posts/" + (this.user.id)),
      'params': {
        room: this.$route.params.roomId
      }
    },"countData":{
      'url': ("posts/" + (this.user.id) + "/count"),
    },"rootHeight":(_vm.scrollListHeight + "px")},scopedSlots:_vm._u([(_vm.isActive)?{key:"staticSlot",fn:function(){return [_c('v-container',{staticClass:"py-0 px-0"},[_c('v-row',{staticClass:"px-0 mb-3",staticStyle:{"width":"100%","max-width":"100%"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"8"}},[(_vm.room)?_c('ActionsHeader',{attrs:{"room":_vm.room,"rerenderPostList":_vm.rerenderPostList}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",staticStyle:{"position":"relative"},attrs:{"cols":"4"}},[_c('SidebarRight')],1)],1)],1)]},proxy:true}:null,{key:"child",fn:function(props){return [_c('v-row',{staticStyle:{"width":"100%","max-width":"100%"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"8"}},[_c('v-card',{staticClass:"mb-3",staticStyle:{"width":"100%"},attrs:{"rounded":"xl","elevation":1},on:{"click":function () { return _vm.$router.push(("/community/" + (_vm.community._id) + "/post/" + (props.item._id))); }}},[_c('Post',{attrs:{"post":props.item,"showComments":false}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}})],1)]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }